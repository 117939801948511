import React, { useState } from 'react';
import { NavLink, useRoutes, Outlet } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Toaster, toast } from 'react-hot-toast';
import { Popover, PopoverBody } from 'reactstrap';
import Avatar from 'react-avatar';

import { APIENDPOINT, axiosInstance } from './config';
import { logout, collapseSidebar, expandSidebar } from './actions/appSlice';
import './App.scss';
import addIcons from './actions/addIcons';
import hamburger from './assets/img/hamburger.svg';
import logo from './assets/img/logo_white.svg';
import logoAdmin from './assets/img/logo_admin.png';
import iconDashboard from './assets/img/icon-dashboard-normal-black.svg';
import iconClassroom from './assets/img/icon-classroom-solid.svg';
import iconUser from './assets/img/icon-identity-mgt_active.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ROLES } from './config';

addIcons();

const Login = React.lazy(() => import('./views/Login/Login'));
const ForgotPassword = React.lazy(() => import('./views/ForgotPassword/ForgotPassword'));
const ResetPassword = React.lazy(() => import('./views/ResetPassword/ResetPassword'));
const Dashboard = React.lazy(() => import('./views/Dashboard/Dashboard'));
const ClassroomManagement = React.lazy(() => import('./views/ClassroomManagement/ClassroomManagement'));
const Classroom = React.lazy(() => import('./views/Classroom/Classroom'));
const Admin = React.lazy(() => import('./views/Admin/Admin'));
const StudentManagement = React.lazy(() => import('./views/StudentManagement/StudentManagement'));

function Layout() {
  return (
    <>
      <Toaster position='top-right' />
      <Outlet />
    </>
  )
};

function AdminLayout() {

  const dispatch = useDispatch()

  const token = useSelector(state => state.app.token);
  const { firstname, lastname } = useSelector(state => state.app.user);
  const role = useSelector(state => state.app.role);

  const isSidebarOpen = useSelector(state => state.app.isSidebarOpen);
  const expandCollapseSidebar = () => {
    isSidebarOpen ? dispatch(collapseSidebar()) : dispatch(expandSidebar());
  };

  const [isUserPopoverOpen, setIsUserPopoverOpen] = useState(false);
  const [isLoggingOut, sestIsLoggingOut] = useState(false);

  const logOut = async() => {
    try {
      sestIsLoggingOut(true);
      await axiosInstance({
        url: `${APIENDPOINT}/logout`,
        method: 'POST',
        headers: { 
          'Authorization': `Bearer ${token}`
        }
      });
      toast.custom(<div className="alert alert-light d-flex align-items-center justify-content-start shadow-sm" style={{ width: '350px' }}>
        <div>
          <FontAwesomeIcon icon="check-circle" size="2x" className="me-3 text-estorya" />
        </div>
        <div>
          <h6 className="fw-bold mb-0 text-estorya">Successfully Logged Out</h6>
        </div>
      </div>);
      
      dispatch(logout());
    } catch(e) {
      console.log(e);
    } finally {
      dispatch(logout());
      sestIsLoggingOut(false);
    }
  };

  const [offCanvasNavIsOpen, setOffCanvasIsOpen] = useState(false);

  function renderNav() {
    return (
      <nav className="main-nav nav flex-column mt-4">
        <li className="nav-item">
          <NavLink to="/" className={({ isActive }) => `btn btn-outline-estoryamenuactive rounded-0 text-start nav-link py-3 d-flex align-items-center ${isActive ? 'active text-estorya' : 'text-white'}`}>
            <img alt="" src={iconDashboard} className="me-2" />
            <span>Dashboard</span>
          </NavLink>
          {role === ROLES.SCHOOLADMIN &&
            <NavLink to="/classroommanagement" className={({ isActive }) => `btn btn-outline-estoryamenuactive rounded-0 text-start nav-link py-3 d-flex align-items-center ${isActive ? 'active text-estorya' : 'text-white'}`}>
              <img alt="" src={iconClassroom} className="me-2" />
              <span>Classroom Management</span>
            </NavLink>
          }
          {role === ROLES.TEACHER &&
            <NavLink to="/studentmanagement" className={({ isActive }) => `btn btn-outline-estoryamenuactive rounded-0 text-start nav-link py-3 d-flex align-items-center ${isActive ? 'active text-estorya' : 'text-white'}`}>
              <img alt="" src={iconClassroom} className="me-2" />
              <span>Student Management</span>
            </NavLink>
          }
        </li>
      </nav>
    )
  }

  return (
    <div id="main">
      <nav className="d-flex" style={{ height: '75px' }}>
        <div className="container-fluid d-flex flex-grow-1">
          <div className="row no-gutters d-flex flex-grow-1 align-items-center">
            <div className="col-2 d-flex align-items-center justify-content-between">
              <div className="d-inline-block px-3">
                <NavLink to="/" className="navbar-brand">
                  <img alt="Project estorya" src={logo} width="150" />
                </NavLink>
              </div>
              <div className="d-none d-md-block">
                <button type="button" className="btn px-2" onClick={expandCollapseSidebar}><img alt="hamburger" src={hamburger} /></button>
              </div>
              <div className="d-md-none">
                <button type="button" className="btn px-2" onClick={() => { setOffCanvasIsOpen(!offCanvasNavIsOpen) }}><img alt="hamburger" src={hamburger} /></button>
              </div>
            </div>
            <div className="col d-flex align-items-center justify-content-end">
              
              {/* <div className="d-none d-md-block bg-white me-2">
                <button type="button" className="btn btn-sm btn-outline-estorya px-2 py-1 rounded-1 btn-network">
                  Network
                  
                  <FontAwesomeIcon icon="chevron-down" size="xs" className="ms-4" />
                </button>
              </div> */}

              {/* <div className="d-none d-md-block">
                <button type="button" className="btn px-2">
                  <img alt="" src={iconApp} />
                </button>
              </div> */}

              <button type="button" id="userPopoverButton" className="btn">
                <Avatar name={`${firstname} ${lastname}`} size="30" round={true} />
                <FontAwesomeIcon icon="chevron-down" size="sm" className="ms-2 text-white" />
              </button>
              <Popover placement="bottom-end" isOpen={isUserPopoverOpen} target="userPopoverButton" toggle={() => { setIsUserPopoverOpen(!isUserPopoverOpen); }} trigger="legacy">
                <PopoverBody style={{ width: '280px' }}>
                  <div className="d-flex align-items-start">
                    <div className="me-2">
                      <Avatar name={`${firstname} ${lastname}`} size="45" round={true} />
                    </div>
                    <div>
                      <h6 className="mb-1">Hello, <span style={{ textTransform: 'capitalize' }}>{firstname}</span>!</h6>
                      <p>Welcome to eStorya</p>
                    </div>
                  </div>
                  
                  <div className="d-flex align-items-start">
                    {role === ROLES.SCHOOLADMIN &&
                      <NavLink to="/admin" className="btn btn-sm btn-estorya me-2">Go To Administration</NavLink>
                    }
                    <button type="button" className="btn btn-sm btn-danger" disabled={isLoggingOut} onClick={logOut}>Logout</button>
                  </div>
                </PopoverBody>
              </Popover>
            </div>
          </div>
        </div>
      </nav>
      <div className="container-fluid d-flex" style={{ height: `calc(100vh - 75px)` }}>
        <div className="row no-gutters flex-grow-1">
          {isSidebarOpen &&
            <div className="col-2 p-0 d-md-none d-lg-block">
              {renderNav()}
            </div>
          }
          <div className={`offcanvas offcanvas-end ${offCanvasNavIsOpen ? 'show' : ''} d-block d-sm-none`}>
            <div className="offcanvas-header">
              <button type="button" className="btn-close" onClick={() => { setOffCanvasIsOpen(!offCanvasNavIsOpen) }}></button>
            </div>
            <div className="offcanvas-body">
              {renderNav()}
            </div>
          </div>

          <div className="col bg-white p-0" style={{ borderRadius: isSidebarOpen ? '20px 0 0 0' : '0px' }}>
            <div className="main">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

function SuperAdminLayout() {

  const dispatch = useDispatch()

  const token = useSelector(state => state.app.token);
  const { firstname, lastname } = useSelector(state => state.app.user);

  const [isUserPopoverOpen, setIsUserPopoverOpen] = useState(false);
  const [isLoggingOut, sestIsLoggingOut] = useState(false);

  const logOut = async() => {
    try {
      sestIsLoggingOut(true);
      await axiosInstance({
        url: `${APIENDPOINT}/logout`,
        method: 'POST',
        headers: { 
          'Authorization': `Bearer ${token}`
        }
      });

      toast.custom(<div className="alert alert-light d-flex align-items-center justify-content-start shadow-sm" style={{ width: '350px' }}>
        <div>
          <FontAwesomeIcon icon="check-circle" size="2x" className="me-3 text-estorya" />
        </div>
        <div>
          <h6 className="fw-bold mb-0 text-estorya">Successfully Logged Out</h6>
        </div>
      </div>);
    } catch(e) {
      console.log(e);
    } finally {
      dispatch(logout());
      sestIsLoggingOut(false);
    }
  };

  return (
    <div id="main">
      <nav className="d-flex" style={{ height: '75px' }}>
        <div className="container-fluid d-flex flex-grow-1">
          <div className="row no-gutters d-flex flex-grow-1 align-items-center">
            <div className="col-2 h-100 d-flex align-items-center">
              <div className="d-inline-block">
                <NavLink to="/admin" className="navbar-brand">
                  <img alt="eStorya: Administration" src={logoAdmin} width="280" />
                </NavLink>
              </div>
            </div>
            <div className="col d-flex align-items-center justify-content-end h-100 bg-light">
              <button type="button" id="userPopoverButton" className="btn">
                <Avatar name={`${firstname} ${lastname}`} size="30" round={true} />
                <FontAwesomeIcon icon="chevron-down" size="sm" className="ms-2" />
              </button>
              <Popover placement="bottom-end" isOpen={isUserPopoverOpen} target="userPopoverButton" toggle={() => { setIsUserPopoverOpen(!isUserPopoverOpen); }} trigger="legacy">
                <PopoverBody style={{ width: '280px' }}>
                  <div className="d-flex align-items-start">
                    <div className="me-2">
                      <Avatar name={`${firstname} ${lastname}`} size="45" round={true} />
                    </div>
                    <div>
                      <h6 className="mb-1">Hello {firstname}!</h6>
                      <p>Welcome to eStorya</p>
                    </div>
                  </div>
                  <div className="d-flex align-items-start">
                    <NavLink to="/" className="btn btn-sm btn-estorya me-2">Back to eStorya </NavLink><br/>
                    <button type="button" className="btn btn-sm btn-danger" disabled={isLoggingOut} onClick={logOut}>Logout</button>
                  </div>
                </PopoverBody>
              </Popover>
            </div>
          </div>
        </div>
      </nav>
      <div className="container-fluid d-flex" style={{ height: `calc(100vh - 75px)` }}>
        <div className="row no-gutters flex-grow-1">
          <div className="col-2 p-0">
            <nav className="nav flex-column mt-4 nav-admin">
              <li className="nav-item px-3 mb-3">
                <NavLink to="/" className="btn btn-light text-estorya w-100 "><FontAwesomeIcon icon="chevron-left" className="me-2" size="xs" /> Back to eStorya</NavLink>
              </li>
              <li className="nav-item">
                <span className="text-white nav-link fw-bold text-uppercase py-2">Access Control</span>
              </li>
              <li className="nav-item">
                <NavLink to="/admin/accesscontrol" className={({ isActive }) => `btn btn-outline-light rounded-0 text-start nav-link py-3 d-flex align-items-center  ${isActive ? 'active bg-light' : ''}`}>
                  <img alt="" src={iconUser} className="me-2" />
                  <span>Users</span>
                </NavLink>
              </li>
            </nav>
          </div>
          <div className="col bg-light p-0" style={{ borderRadius: '0px' }}>
            <div className="main">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

const routes = (isSignedIn, role) => [
  {
    element: <Layout />,
    children: [
      { path: '/login', exact: true, name: 'Login', element: !isSignedIn ? <Login /> : <Navigate to="/" /> },
      { path: '/forgot-password', exact: true, name: 'ForgotPassword', element: !isSignedIn ? <ForgotPassword /> : <Navigate to="/" /> },
      { path: '/reset-password', exact: true, name: 'ResetPassword', element: !isSignedIn ? <ResetPassword /> : <Navigate to="/" /> },
      { 
        element: <AdminLayout />,
        children: [
          { path: '/', index: true, exact: true, name: 'Dashboard', element: isSignedIn ? <Dashboard /> : <Navigate to="/login" /> },
          { path: '/classroommanagement/*', name: 'Classroom Management', element: isSignedIn ? role === ROLES.SCHOOLADMIN ? <ClassroomManagement /> : <Navigate to="/" /> : <Navigate to="/login" />},
          { path: '/classroommanagement/classrooms/:id', name: 'Classroom', element: isSignedIn ? role === ROLES.SCHOOLADMIN ? <Classroom /> : <Navigate to="/" /> : <Navigate to="/login" />},
          { path: '/studentmanagement/*', name: 'Student Management', element: isSignedIn ? role === ROLES.TEACHER ? <StudentManagement /> : <Navigate to="/" /> : <Navigate to="/login" />},
          { path: '/studentmanagement/classrooms/:id', name: 'Classroom', element: isSignedIn ? role === ROLES.TEACHER ? <Classroom /> : <Navigate to="/" /> : <Navigate to="/login" />}
        ]
      },
      {
        element: <SuperAdminLayout />,
        children: [
          { path: '/admin/*', name: 'Admin', element: isSignedIn ? <Admin /> : <Navigate to="/login" /> },
        ]
      }
    ]
  }
];

function App() {
  const isSignedIn = useSelector(state => state.app.isSignedIn);
  const role = useSelector(state => state.app.role);

  const routing = useRoutes(routes(isSignedIn, role))
  return routing;
};

export default App;