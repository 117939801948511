import axios from "axios";

export const LOCAL = false;

let apiConfig = {
  apiEndpoint: process.env.REACT_APP_SERVICES_API_URL,
  apiEndpointInsights: process.env.REACT_APP_INSIGHTS_API_URL,
  apiEndpointAuth: process.env.REACT_APP_AUTH_API_URL
}

// if(LOCAL) {
//   apiConfig.apiEndpoint = 'https://estorya-services-staging.azurewebsites.net/api';
//   apiConfig.apiEndpointAuth = 'https://estorya-services-staging.azurewebsites.net/api';
//   apiConfig.apiEndpointInsights = 'https://estorya-insights-staging.azurewebsites.net/api';
//   // apiConfig.apiEndpointAuth = 'https://asia-east1-ace-sector-251421.cloudfunctions.net/estorya'
// }

// export const APIENDPOINT = 'https://estorya-api-n5yb9.ondigitalocean.app/api';
export let APIENDPOINT_AUTH = apiConfig.apiEndpointAuth;
export const APIENDPOINT = apiConfig.apiEndpoint;
export const APIENDPOINT_INSIGHTS = apiConfig.apiEndpointInsights;
export const axiosInstance = axios.create();

export const ROLES = {
  SCHOOLADMIN: 'Superadmin',
  TEACHER: 'Teacher',
  STUDENT: 'Student'
};

export const SCHOOLID = 1;
